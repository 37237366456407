import React, { useState, useContext } from 'react';
import AuthContext from '../../../base/components/AuthProvider';

const AdminPanelMembershipTypeUpdate = ({ membershipType, onClose, onUpdate }) => {
  const { authTokens } = useContext(AuthContext);
  const [description, setDescription] = useState(membershipType.description);
  const [price, setPrice] = useState(membershipType.price ? membershipType.price.toString() : '');

  const [error, setError] = useState('');

  const handlePriceChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      if (parseInt(value, 10) > 1000000) {
        setError('El precio no puede superar 1.000.000.');
      } else {
        setPrice(value);
        setError('');
      }
    } else {
      setError('Por favor, ingrese un número entero válido.');
    }
  };

  const validateForm = () => {
    const newErrors = {};
    if (!description.trim()) {
      newErrors.description = 'Este campo es obligatorio';
    }
    // if (description.trim().length < 10 || description.trim().length > 550) {
    //   newErrors.description = 'La descripción debe tener entre 10 y 550 caracteres';
    // }
    if (!price.trim()) {
      newErrors.price = 'El campo de precio no puede estar vacío.';
    } else if (isNaN(price)) {
      newErrors.price = 'El precio debe ser un número.';
    } else if (parseFloat(price) <= 0) {
      newErrors.price = 'El precio debe ser mayor que cero.';
    } else if (parseFloat(price) > 1000000) {
      newErrors.price = 'El precio no puede superar los 1000000.';
    }
    return newErrors;
  };

  const handleSave = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();
    if (Object.keys(newErrors).length === 0) {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/admin/membership-types/${membershipType.id}/update/`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
          body: JSON.stringify({ description, price }),
        });
        if (response.ok) {
          const updatedMembershipType = await response.json();
          onUpdate(updatedMembershipType);
          onClose();
        } else {
          console.error('Error al actualizar la membresía:', response.statusText);
        }
      } catch (error) {
        console.error('Error al actualizar la membresía:', error.message);
      }
    } else {
      setError(newErrors);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h3 className="popup-title">Actualizar Membresía</h3>
          </div>
          <form className="popup-form" onSubmit={handleSave}>
            <div className="popup-form-rows">
              <div className="popup-form-double-col">
                <div className={`popup-basic-form-row`}>
                  <label>Nombre</label>
                  <input 
                    type="text" 
                    value={membershipType.name} 
                    readOnly
                    disabled
                  />
                  <p className="error-message">{'\u00A0'}</p>
                </div>
                <div className={`popup-basic-form-row ${error.price ? 'missing-field' : ''}`}>
                  <label>Precio</label>
                  <input 
                    type="text" 
                    value={price} 
                    onChange={handlePriceChange} 
                    placeholder="$" 
                  />
                  <p className="error-message">{error.price || '\u00A0'}</p>
                </div>
              </div>
              <div className={`popup-basic-form-row ${error.description ? 'missing-field' : ''}`}>
                <label>Descripción</label>
                <textarea value={description} onChange={(e) => setDescription(e.target.value)}/>
                <p className="error-message">{error.description || '\u00A0'}</p>
              </div>
              <div className="buttons-container">
                <button className="primary-btn" type="submit">Guardar Cambios</button>
                <button className="secondary-btn" onClick={onClose}>Cancelar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AdminPanelMembershipTypeUpdate;
