import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../base/components/AuthProvider';
import LoadingSpinner from '../../common/components/LoadingSpinner';
import HH_Star1 from '../../images/Vectors/HH_Star1.svg';
import HH_Star2 from '../../images/Vectors/HH_Star2.svg';
import HH_Star3 from '../../images/Vectors/HH_Star3.svg';
import MembershipPaymentMethod from '../../base/components/MembershipType/MembershipPaymentMethod';
import { Helmet } from 'react-helmet';
import HeroSectionImage from '../../images/HomePage/HeroSection/HappyHome_HeroSectionImage.png'

const MembershipsPage = ({ setSignUpVisible }) => {
  const [membershipTypes, setMembershipTypes] = useState([]);
  const [selectedMembership, setSelectedMembership] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { userData, authTokens } = useContext(AuthContext);

  useEffect(() => {
    const fetchMembershipTypes = async () => {
      try {
        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/membership-types/get-all/`, {
          method: 'GET'
        });
        if (response.ok) {
          const data = await response.json();
          setMembershipTypes(data);
        } else {
          throw new Error('Failed to fetch membership types');
        }
      } catch (error) {
        setError(error.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchMembershipTypes();
  }, [authTokens]);

  const handleButtonClick = (membershipType) => {
    setSelectedMembership(membershipType);
  };

  const closePopup = () => {
    setSelectedMembership(null);
  };

  const getStarImage = (membershipType) => {
    switch (membershipType.name) {
      case 'Plus':
        return HH_Star1;
      case 'Premium':
        return HH_Star2;
      case 'Elite':
        return HH_Star3;
      default:
        return null;
    }
  };

  return (
    <div className="memberships-page-container">
      <Helmet>
        <title>HappyHome - Membresias para Empleadores</title>
        <meta name="description" content="Descubrí nuestras opciones de membresías para empleadores en Argentina. Accedé a beneficios exclusivos como verificación de antecedentes, selección de personal y más." />
        <meta name="keywords" content="membresías, opciones de membresía, beneficios, empleadores en Argentina, verificación de antecedentes, selección de personal, contratación de personal doméstico" />
        <meta property="og:title" content="HappyHome - Membresias para Empleadores" />
        <meta property="og:description" content="La forma más fácil, rápida y económica de encontrar ayuda integral para tu hogar." />
        <meta property="og:image" content={HeroSectionImage} />
        <meta property="og:url" content="http://www.happyhome.com.ar" />
      </Helmet>
      <div className="section-title">
        <h2>Nuestras membresias</h2>
        <p>Encontrá la membresía perfecta para tus necesidades de contratación</p>
      </div>

      {isLoading ? (
        <LoadingSpinner />
      ) : error ? (
        <div>
          <p className="error-message">Error: {error}</p>
        </div>
      ) : (
        <div className="membership-type-cards-container">
          <div className="membership-type-cards">
            {membershipTypes.map((membershipType, index) => (
              <div key={index} className="membership-type-card">
                <div className="membership-type-card-details">
                  {/* <div className="membership-type-card-star">
                    <img src={getStarImage(membershipType)} alt="star icon" />
                  </div> */}
                  <div className="membership-type-card-title">
                    <h3>Membresía {membershipType.name}</h3>
                    <p>Costo: ${membershipType.price}</p>
                    <hr />
                  </div>
                </div>
                {/* <ul className="membership-type-card-description"> */}
                <ul className="membership-type-card-description" dangerouslySetInnerHTML={{ __html: membershipType.description }} />
                  {/* <li>{membershipType.description}</li>
                </ul> */}
                <div className="membership-type-card-button">
                  {userData ? (
                    userData.membership_type ? (
                      <button className="primary-btn" disabled>Ya tienes una membresía</button>
                    ) : (
                      <button
                        className="primary-btn"
                        onClick={() => handleButtonClick(membershipType)}
                      >
                        Adquirir
                      </button>
                    )
                  ) : (
                    <button
                      className="primary-btn"
                      onClick={() => setSignUpVisible(true)}
                    >
                      Registrarme
                    </button>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      {selectedMembership && (
        <MembershipPaymentMethod
          membership={selectedMembership}
          onClose={closePopup}
        />
      )}
    </div>
  );
};

export default MembershipsPage;
