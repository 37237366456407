import React, { useState, useContext, useEffect } from "react";
import AuthContext from "../AuthProvider";
import { useNavigate } from "react-router-dom";
import UserAccountDetailsUpdate from "./UserAccountDetailsUpdate";
import ParentProfileInfoUpdate from "../ParentProfile/ParentProfileInfo/ParentProfileInfoUpdate";
import LoadingSpinner from "../../../common/components/LoadingSpinner";
// eslint-disable-next-line
import ResendVerificationButton from "../../components/ResendVerificationButton";

const UserAccountDetails = () => {
  const { authTokens, logoutUser, userData } = useContext(AuthContext);
  const [isUpdatingAccount, setIsUpdatingAccount] = useState(false);
  const [isUpdatingParentInfo, setIsUpdatingParentInfo] = useState(false);
  const [userAccountData, setUserAccountData] = useState(null);
  const [parentProfileInfo, setParentProfileInfo] = useState(null);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingParentInfo, setLoadingParentInfo] = useState(true);
  const [error, setError] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        if (authTokens && authTokens.access) {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/user/get-data/`,
            {
              method: "GET",
              headers: {
                Authorization: `Bearer ${authTokens.access}`,
              },
            }
          );

          if (response.ok) {
            const data = await response.json();
            setUserAccountData(data);
          } else {
            throw new Error("Error al obtener los datos del usuario");
          }
        }
      } catch (error) {
        console.error(error);
        setError("Error al cargar los detalles del usuario");
      } finally {
        setLoadingData(false);
      }
    };

    const fetchParentInfo = async () => {
      try {
        if (userData?.profile_id) {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/parent-profile/${userData.profile_id}/get-info/`,
            {
              method: "GET",
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${authTokens.access}`,
              },
            }
          );

          if (response.ok) {
            const data = await response.json();
            setParentProfileInfo(data);
          } else {
            console.error(
              "Error al obtener la información del perfil del padre:",
              response.statusText
            );
          }
        }
      } catch (error) {
        console.error("Error al obtener la información del perfil del padre:", error);
      } finally {
        setLoadingParentInfo(false);
      }
    };

    fetchUserData();
    fetchParentInfo();
  }, [authTokens, userData?.profile_id]);

  const handleModifyProfile = () => {
    setIsUpdatingAccount(true);
  };

  // eslint-disable-next-line
  const handleModifyParentInfo = () => {
    setIsUpdatingParentInfo(true);
  };

  const handleDeleteProfile = async () => {
    const confirmDelete = window.confirm(
      "¿Estás seguro de que quieres eliminar tu cuenta?"
    );
    if (confirmDelete) {
      try {
        if (authTokens && authTokens.access) {
          const response = await fetch(
            `${process.env.REACT_APP_API_BASE_URL}/user/${userAccountData.id}/delete/`,
            {
              method: "DELETE",
              headers: {
                Authorization: `Bearer ${authTokens.access}`,
              },
            }
          );

          if (response.ok) {
            logoutUser();
            navigate("/");
          } else {
            const errorData = await response.json();
            console.error("Error al eliminar la cuenta:", errorData.error);
            setError(`Error: ${errorData.error}`);
          }
        }
      } catch (error) {
        console.error("Error al eliminar la cuenta:", error);
        setError(`Error: ${error.message}`);
      }
    }
  };

  const handleAccountUpdate = (updatedUser) => {
    setUserAccountData(updatedUser);
    setIsUpdatingAccount(false);
  };

  const handleParentInfoUpdate = (updatedInfo) => {
    setParentProfileInfo(updatedInfo);
    setIsUpdatingParentInfo(false);
  };

  return (
    <div className="profile-edit-section">
      <h2 className="profile-edit-section-title">Información personal</h2>
      {loadingData || loadingParentInfo ? (
        <LoadingSpinner />
      ) : (
        <div className="profile-edit-section-content">
          <div className="profile-details">
            <p><strong>Nombre:</strong> {userAccountData?.first_name || "No disponible"}</p>
            <p><strong>Apellido:</strong> {userAccountData?.last_name || "No disponible"}</p>
            <p><strong>Fecha de Nacimiento:</strong> {parentProfileInfo?.date_of_birth || "Sin asignar"}</p>
            <p><strong>Género:</strong> {parentProfileInfo?.gender_display || "Sin asignar"}</p>
            <p><strong>Nacionalidad:</strong> {parentProfileInfo?.nationality || "Sin asignar"}</p>
            <p><strong>Email:</strong> {userAccountData?.email || "No disponible"}</p>
            <p><strong>Tipo de Membresía:</strong> {userAccountData?.membership_type || "Sin asignar"}</p>
            <p><strong>Fecha de Expiración:</strong> {userAccountData?.membership_expiration || "Sin asignar"}</p>
            <p><strong>Estado de la Membresía:</strong> {userAccountData?.membership_status || "Sin asignar"}</p>
          </div>
          <div className="buttons-container">
            <button className="primary-btn" onClick={handleModifyProfile}>Modificar</button>
            {/* <button className="primary-btn" onClick={handleModifyParentInfo}>Modificar información personal</button> */}
            <button className="secondary-btn" onClick={handleDeleteProfile}>Eliminar cuenta</button>
          </div>
        </div>
      )}

      {isUpdatingAccount && (
        <UserAccountDetailsUpdate
          userData={userAccountData}
          onUpdate={handleAccountUpdate}
          onClose={() => setIsUpdatingAccount(false)}
        />
      )}
      {isUpdatingParentInfo && (
        <ParentProfileInfoUpdate
          parentInfo={parentProfileInfo}
          onCancel={() => setIsUpdatingParentInfo(false)}
          onUpdate={handleParentInfoUpdate}
        />
      )}

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default UserAccountDetails;