import WorkerProfileExperiencesList from './WorkerProfileExperience/WorkerProfileExperiencesList';
import WorkerProfileEducationsList from './WorkerProfileEducation/WorkerProfileEducationsList';
import WorkerProfileIntroduction from './WorkerProfileIntroduction/WorkerProfileIntroduction';
import WorkerProfileHourPrice from './WorkerProfileHourPrice/WorkerProfileHourPrice';
import WorkerProfileServicesList from './WorkerProfileService/WorkerProfileServicesList';
import WorkerProfileLanguagesList from './WorkerProfileLanguage/WorkerProfileLanguagesList';
import WorkerProfileTasksList from './WorkerProfileTask/WorkerProfileTasksList';
import WorkerProfileShiftsList from './WorkerProfileShift/WorkerProfileShiftsList';
import WorkerProfileImage from './WorkerProfileImage/WorkerProfileImage';
import WorkerProfileWorkArrangementsList from './WorkerProfileWorkArrangement/WorkerProfileWorkArrangementsList';
import WorkerProfileInfo from './WorkerProfileInfo/WorkerProfileInfo';
import UserAccountDetails from '../UserAccount/UserAccountDetails';

const WorkerProfile = () => {

  return (
    <div className="parent-profile-edit-container">
        <div className="parent-profile-section">
          <div className="container-column">
            <div className="profile-image">
              <WorkerProfileImage />
            </div>
          </div>
          <div className="container-column">
            <div className="account-details">
              <UserAccountDetails />
            </div>
            {/* <div className="info">
              <WorkerProfileInfo />
            </div> */}
          </div>
        </div>

        <div className="parent-profile-section">
        <div className="container-column">
          <div className="introduction">
            <WorkerProfileIntroduction />
          </div>
          <div className="services">
            <WorkerProfileServicesList />
          </div>
          <div className="tasks">
            <WorkerProfileTasksList />
          </div>
          <div className="languages">
            <WorkerProfileLanguagesList />
          </div>
          <div className="work-arrangement">
            <WorkerProfileWorkArrangementsList />
          </div>
          <div className="hour-price">
            <WorkerProfileHourPrice />
          </div>
        </div>
        <div className="container-column">

          <div className="educations">
            <WorkerProfileEducationsList />
          </div>
          <div className="experiences">
            <WorkerProfileExperiencesList />
          </div>
          <div className="shifts">
            <WorkerProfileShiftsList />
          </div>
        </div>

        </div>


    </div>
  );
};

export default WorkerProfile;
