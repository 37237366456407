import React from 'react';
// eslint-disable-next-line
import ParentProfileInfo from './ParentProfileInfo/ParentProfileInfo';
import ParentProfileImage from './ParentProfileImage/ParentProfileImage';
import ParentProfileJobOffersList from './ParentProfileJobOffer/ParentProfileJobOffersList';
import ParentProfileMembership from './ParentProfileMembership/ParentProfileMembership';
import UserAccountDetails from '../UserAccount/UserAccountDetails';

const ParentProfile = () => {
  return (

        <div className="parent-profile-edit-container">
          <div className="parent-profile-data-user-section">
            <div className="container-column">
              <div className="profile-image">
                <ParentProfileImage />
              </div>
            </div>
            <div className="container-column">
              <div className="account-details">
                <UserAccountDetails />
              </div>
              {/* <div className="info">
                <ParentProfileInfo />
              </div> */}
            </div>
          </div>
          
          <div className="parent-profile-section">
            <div className="parent-membership">
              <ParentProfileMembership />
            </div>
          </div>

          <div className="parent-profile-section">
            <div className="job-offers">
              <ParentProfileJobOffersList />
            </div>
          </div>

        </div>
  );
};

export default ParentProfile;
