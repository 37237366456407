import React, { useState, useEffect, useContext } from 'react';
import AuthContext from '../../AuthProvider';

const WorkerProfileEducationUpdate = ({ education, onCancel, onUpdate }) => {
  const { authTokens } = useContext(AuthContext);

  const [formData, setFormData] = useState({ ...education });
  
  // eslint-disable-next-line
  const [originalData, setOriginalData] = useState({ ...education });
  const [errors, setErrors] = useState({});
  const [statusOptions, setStatusOptions] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);
  const [isFormValid, setIsFormValid] = useState(false);
  const [isDataChanged, setIsDataChanged] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const statusResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/education/get-status-choices/`);
        if (statusResponse.ok) {
          const statusData = await statusResponse.json();
          setStatusOptions(statusData.status_choices);
        } else {
          console.error('Failed to fetch status options');
        }

        const typeResponse = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/education/get-type-choices/`);
        if (typeResponse.ok) {
          const typeData = await typeResponse.json();
          setTypeOptions(typeData.type_choices);
        } else {
          console.error('Failed to fetch type options');
        }
      } catch (error) {
        console.error('Error fetching options:', error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const dataChanged = JSON.stringify(formData) !== JSON.stringify(originalData);
    setIsDataChanged(dataChanged);
  }, [formData, originalData]);

  useEffect(() => {
    const newErrors = validateForm();
    setIsFormValid(Object.keys(newErrors).length === 0);
  }, [formData]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateForm = () => {
    const newErrors = {};

    if (!formData.title.trim()) {
      newErrors.title = 'Este campo es obligatorio';
    } else if (formData.title.trim().length < 3 || formData.title.trim().length > 80) {
      newErrors.title = 'El título debe tener entre 3 y 80 caracteres';
    }
  
    if (!formData.place.trim()) {
      newErrors.place = 'Este campo es obligatorio';
    } else if (formData.place.trim().length < 2 || formData.place.trim().length > 100) {
      newErrors.place = 'La institución debe tener entre 5 y 100 caracteres';
    }
  
    if (!formData.status) {
      newErrors.status = 'Este campo es obligatorio';
    }
  
    if (!formData.type) {
      newErrors.type = 'Este campo es obligatorio';
    }
  
    return newErrors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const newErrors = validateForm();

    if (Object.keys(newErrors).length === 0) {
      try {
        const finalFormData = { ...formData };

        const response = await fetch(`${process.env.REACT_APP_API_BASE_URL}/worker-profile/education/${finalFormData.id}/update/`, {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${authTokens?.access}`,
          },
          body: JSON.stringify(finalFormData),
        });
        
        if (response.ok) {
          const updatedEducation = await response.json();
          onUpdate(updatedEducation);
        } else {
          console.error('Error al actualizar la educación:', response.statusText);
        }
      } catch (error) {
        console.error('Error al actualizar la educación:', error.message);
      }
    } else {
      setErrors(newErrors);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <div className="popup-intro">
            <h3 className="popup-title">Modificar Educación</h3>
            <div className="popup-intro-description">
              <p>Contanos si estudiaste algo o lo estás haciendo actualmente</p>
            </div>
          </div>
          
          <form className="popup-form" onSubmit={handleSubmit}>
            <div className="popup-form-rows">
              <div className="popup-form-double-col">
                <div className={`popup-basic-form-row ${errors.title ? 'missing-field' : ''}`}>
                  <label>Título</label>
                  <input type="text" name="title" value={formData.title} onChange={handleChange} />
                  <p className="error-message">{errors.title || '\u00A0'}</p>
                </div>

                <div className={`popup-basic-form-row ${errors.place ? 'missing-field' : ''}`}>
                  <label>Institución</label>
                  <input type="text" name="place" value={formData.place} onChange={handleChange} />
                  <p className="error-message">{errors.place || '\u00A0'}</p>
                </div>
              </div>

              <div className="popup-form-double-col">
                <div className={`popup-basic-form-row ${errors.status ? 'missing-field' : ''}`}>
                  <label>Estado</label>
                  <select name="status" value={formData.status} onChange={handleChange}>
                    <option value="">Seleccionar estado</option>
                    {statusOptions.map(option => (
                      <option key={option[0]} value={option[0]}>{option[1]}</option>
                    ))}
                  </select>
                  <p className="error-message">{errors.status || '\u00A0'}</p>
                </div>

                <div className={`popup-basic-form-row ${errors.type ? 'missing-field' : ''}`}>
                  <label>Disciplina</label>
                  <select name="type" value={formData.type} onChange={handleChange}>
                    <option value="">Seleccionar disciplina</option>
                    {Object.entries(typeOptions).map(([value, label]) => (
                      <option key={value} value={value}>{label}</option>
                    ))}
                  </select>
                  <p className="error-message">{errors.type || '\u00A0'}</p>
                </div>
              </div>

              <div className="buttons-container">
                <button className="primary-btn" type="submit" disabled={!isFormValid || !isDataChanged}>Guardar Cambios</button> {/* Botón deshabilitado */}
                <button className="secondary-btn" onClick={onCancel}>Cancelar</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WorkerProfileEducationUpdate;
