import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import AuthContext from "../components/AuthProvider";
import LoadingSpinner from "../../common/components/LoadingSpinner";

const SignUp = ({ onClose }) => {
  const { loginUser, authTokens } = useContext(AuthContext);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    confirmPassword: "",
    profileType: "",
  });
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // const handleSignUp = async () => {
  //   setLoading(true);
  //   setFormSubmitted(true);
  //   const formErrors = {};
  //   if (!formData.firstName) {
  //     formErrors.firstName = true;
  //   }
  //   if (!formData.lastName) {
  //     formErrors.lastName = true;
  //   }
  //   if (!formData.email) {
  //     formErrors.email = true;
  //   }
  //   if (!formData.password) {
  //     formErrors.password = true;
  //   }
  //   if (!formData.confirmPassword) {
  //     formErrors.confirmPassword = true;
  //   }
  //   if (!formData.profileType) {
  //     formErrors.profileType = true;
  //   }

  //   if (Object.keys(formErrors).length > 0) {
  //     setErrorMessage("Completar campos obligatorios.");
  //     setLoading(false);
  //     return;
  //   }

  //   if (!validateEmail(formData.email)) {
  //     formErrors.emailInvalid = true;
  //     setErrorMessage("Por favor ingresa un correo electrónico válido.");
  //     setLoading(false);
  //     return;
  //   }

  //   if (formData.password !== formData.confirmPassword) {
  //     formErrors.passwordMismatch = true;
  //     setErrorMessage("Las contraseñas no coinciden.");
  //     setLoading(false);
  //     return;
  //   }

  //   const passwordRegex = /^(?=.*\d).{8,}$/;
  //   if (!passwordRegex.test(formData.password)) {
  //     formErrors.passwordInvalid = true;
  //     setErrorMessage(
  //       "La contraseña debe tener al menos 8 caracteres y contener al menos un número."
  //     );
  //     setLoading(false);
  //     return;
  //   }

  //   const data = { ...formData };

  //   try {
  //     if (authTokens) {
  //       setErrorMessage("Ya tienes la sesión iniciada.");
  //       setLoading(false);
  //       return;
  //     }

  //     const registerResponse = await fetch(
  //       `${process.env.REACT_APP_API_BASE_URL}/sign-up/`,
  //       {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify(data),
  //       }
  //     );

  //     const registerData = await registerResponse.json();

  //     if (registerResponse.status === 201) {

  //       const loginResult = await loginUser({
  //         email: data.email,
  //         password: data.password,
  //       });

  //       setConfirmationMessage(
  //         "te enviamos un mail para completar tu registro, si no te llega revisa la casilla de correo no deseado"
  //       );
  //       setErrorMessage("");

  //       if (loginResult.success) {
  //         onClose();
  //         navigate("/user-account");
  //       } else {
  //         setErrorMessage("Error logging in: " + loginResult.message);
  //       }
  //     } else if (registerResponse.status === 400) {
  //       const error_message = "El tipo de perfil seleccionado no es valido.";
  //       setErrorMessage(error_message);
  //     } else if (registerResponse.status === 409) {
  //       const error_message = "El correo electrónico ya esta registrado.";
  //       setErrorMessage(error_message);
  //     } else if (registerResponse.status === 500) {
  //       const error_message = registerData.error;
  //       setErrorMessage(error_message);
  //     } else {
  //       setErrorMessage(registerData.error);
  //     }
  //   } catch (error) {
  //     setErrorMessage("Error: " + error.message);
  //   } finally {
  //     setLoading(false);
  //   }
  // };

  const handleSignUp = async () => {
    setLoading(true);
    setFormSubmitted(true);
    const formErrors = {};
    if (!formData.firstName) {
      formErrors.firstName = true;
    }
    if (!formData.lastName) {
      formErrors.lastName = true;
    }
    if (!formData.email) {
      formErrors.email = true;
    }
    if (!formData.password) {
      formErrors.password = true;
    }
    if (!formData.confirmPassword) {
      formErrors.confirmPassword = true;
    }
    if (!formData.profileType) {
      formErrors.profileType = true;
    }

    if (Object.keys(formErrors).length > 0) {
      setErrorMessage("Completar campos obligatorios.");
      setLoading(false);
      return;
    }

    if (!validateEmail(formData.email)) {
      formErrors.emailInvalid = true;
      setErrorMessage("Por favor ingresa un correo electrónico válido.");
      setLoading(false);
      return;
    }

    if (formData.password !== formData.confirmPassword) {
      formErrors.passwordMismatch = true;
      setErrorMessage("Las contraseñas no coinciden.");
      setLoading(false);
      return;
    }

    const passwordRegex = /^(?=.*\d).{8,}$/;
    if (!passwordRegex.test(formData.password)) {
      formErrors.passwordInvalid = true;
      setErrorMessage(
        "La contraseña debe tener al menos 8 caracteres y contener al menos un número."
      );
      setLoading(false);
      return;
    }

    const data = { ...formData };

    try {
      if (authTokens) {
        setErrorMessage("Ya tienes la sesión iniciada.");
        setLoading(false);
        return;
      }

      const registerResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/sign-up/`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(data),
        }
      );

      const registerData = await registerResponse.json();

      if (registerResponse.status === 201) {
        const loginResult = await loginUser({
          email: data.email,
          password: data.password,
        });

        setConfirmationMessage(
          "Te enviamos un mail para completar tu registro, si no te llega revisa la casilla de correo no deseado"
        );

        setErrorMessage("");

        if (loginResult.success) {
          navigate("/user-account");
        } else {
          setErrorMessage("Error logging in: " + loginResult.message);
        }
      } else if (registerResponse.status === 400) {
        const error_message = "El tipo de perfil seleccionado no es valido.";
        setErrorMessage(error_message);
      } else if (registerResponse.status === 409) {
        const error_message = "El correo electrónico ya esta registrado.";
        setErrorMessage(error_message);
      } else if (registerResponse.status === 500) {
        const error_message = registerData.error;
        setErrorMessage(error_message);
      } else {
        setErrorMessage(registerData.error);
      }
    } catch (error) {
      setErrorMessage("Error: " + error.message);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          {confirmationMessage ? (
            <div className="popup-intro">
              
              <div className="popup-intro-description">
                <p>{confirmationMessage}</p>
              </div>

                <div className="buttons-container">
                  <button type="button" className="secondary-btn" onClick={onClose}>
                    Cerrar
                  </button>
                </div>
                
            </div>
          ) : (
            <>
              <div className="popup-intro">
                <h2>Registrarse</h2>
                <div className="popup-intro-description">
                  <p>
                    Para ingresar en la web utilizarás tu correo electrónico,
                    por favor ingresa uno al cual tengas acceso. Enviaremos un
                    email de confirmación para completar tu registro.
                  </p>
                </div>
              </div>
              <div className="popup-auth-message">
                <p className="error-message">{errorMessage || "\u00A0"}</p>
              </div>
              {loading && <LoadingSpinner />}
              <form className="popup-form">
                <div className="popup-form-rows">
                  <div className="popup-form-double-col">
                    <div
                      className={`form-group ${
                        formSubmitted && !formData.firstName
                          ? "missing-field"
                          : ""
                      }`}
                    >
                      <label>Tu nombre sera visible</label>
                      <input
                        type="text"
                        placeholder="Nombre"
                        name="firstName"
                        value={formData.firstName}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </div>
                    <div
                      className={`form-group ${
                        formSubmitted && !formData.lastName
                          ? "missing-field"
                          : ""
                      }`}
                    >
                      <label>No mostraremos tu apellido</label>
                      <input
                        type="text"
                        placeholder="Apellido"
                        name="lastName"
                        value={formData.lastName}
                        onChange={handleChange}
                        disabled={loading}
                      />
                    </div>
                  </div>
                  <div
                    className={`form-group ${
                      formSubmitted && !formData.email ? "missing-field" : ""
                    }`}
                  >
                    <label>
                      Por favor ingresa un correo electronico valido.
                    </label>
                    <input
                      type="email"
                      placeholder="Correo electrónico"
                      name="email"
                      value={formData.email}
                      onChange={handleChange}
                      disabled={loading}
                    />
                  </div>
                  <div
                    className={`form-group ${
                      formSubmitted && !formData.password ? "missing-field" : ""
                    }`}
                  >
                    <label>
                      La contraseña debe tener números y mínimo 8 caracteres.
                    </label>
                    <input
                      type="password"
                      placeholder="Contraseña"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      disabled={loading}
                    />
                  </div>
                  <div
                    className={`form-group ${
                      formSubmitted && !formData.confirmPassword
                        ? "missing-field"
                        : ""
                    }`}
                  >
                    <label>
                      Ingresa tu contraseña una vez más (¡y no la olvides!).
                    </label>
                    <input
                      type="password"
                      placeholder="Repetir contraseña"
                      name="confirmPassword"
                      value={formData.confirmPassword}
                      onChange={handleChange}
                      disabled={loading}
                    />
                  </div>
                  <div
                    className={`form-group ${
                      formSubmitted && !formData.profileType
                        ? "missing-field"
                        : ""
                    }`}
                  >
                    <label>
                      ¿Cómo quieres utilizar la web? (No podrás cambiarlo)
                    </label>
                    <select
                      name="profileType"
                      value={formData.profileType}
                      onChange={handleChange}
                      disabled={loading}
                    >
                      <option value="">Selecciona el tipo de perfil</option>
                      <option value="Worker">Soy Trabajador</option>
                      <option value="Parent">Soy Empleador</option>
                    </select>
                  </div>
                </div>
                <div className="buttons-container">
                  <button
                    className="secondary-btn"
                    type="button"
                    onClick={handleSignUp}
                    disabled={loading}
                  >
                    Registrarse
                  </button>
                  <button
                    className="secondary-btn"
                    type="button"
                    onClick={onClose}
                    disabled={loading}
                  >
                    Cancelar
                  </button>
                </div>
                <div className="popup-footer">
                  <p>
                    Para ingresar a la web necesitarás un correo electrónico al
                    cual tengas acceso
                  </p>
                  <p>
                    Al crear una cuenta en nuestro sitio web aceptas los{" "}
                    <a href="/terms">términos y condiciones.</a>
                  </p>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SignUp;
