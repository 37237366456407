import React, { useState } from "react";
import defaultProfileImage from "../../../images/ProfilePicture/HH_ProfileDefaultImage.png";
import LoadingSpinner from "../../../common/components/LoadingSpinner";

const WorkerCard = ({ worker }) => {
  const [loading, setLoading] = useState(true);
  const workerAge = worker.date_of_birth
    ? `${
        new Date().getFullYear() - new Date(worker.date_of_birth).getFullYear()
      } años`
    : "";

  const handleImageLoad = () => {
    setLoading(false);
  };

  return (
    <div className="worker-card">
      <div className="worker-container">
        <div className="left-container">
          <div className="image-container">
            {loading && <LoadingSpinner />}
            <img
              src={worker.user.profile_image || defaultProfileImage}
              alt="Worker Card Profile"
              className={`worker-profile-image ${loading ? "hidden" : ""}`}
              onLoad={handleImageLoad}
            />
          </div>
        </div>

        <div className="right-container">
          <div>
            <div className="worker-top-container">
              <div className="worker-name">
                <p>
                  <strong>{worker.user.first_name}</strong>{" "}
                </p>
              </div>
            </div>

            <p className="worker-detail-line">
              {workerAge ? `${workerAge}, ` : ""}
              {worker.location?.municipality?.name &&
              worker.location?.province?.name
                ? `${worker.location.municipality.name}, ${worker.location.province.name}`
                : "Ubicación sin asignar"}
            </p>
          </div>
          <div className="worker-about">
            <div className="worker-introduction">
              <p>
                {worker.introduction
                  ? worker.introduction.substring(0, 160)
                  : ""}
                {worker.introduction &&
                  worker.introduction.length > 160 &&
                  "..."}
              </p>
            </div>

            <div className="worker-services-none-hr">
              {worker.services.map((service) => (
                <p className="worker-service" key={service.id}>
                  {service.title_display}
                </p>
              ))}
            </div>

            <div className="worker-work-arrangements">
              {worker.work_arrangements &&
              worker.work_arrangements.length > 0 ? (
                worker.work_arrangements.map((work_arrangement, index) => (
                  <span
                    className="worker-work-arrangement"
                    key={work_arrangement.id}
                  >
                    {work_arrangement.value_display}
                    {index < worker.work_arrangements.length - 1 && ", "}
                  </span>
                ))
              ) : (
                <p className="worker-work-arrangement">
                  Modalidad de trabajo sin asignar
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkerCard;
