import React, { useState, useContext, useEffect } from 'react';
import AuthContext from '../AuthProvider';

const UserProfileUpdate = ({ userData, parentInfo, onUpdate, onClose }) => {
  const { authTokens, userData: contextUserData } = useContext(AuthContext);

  // Estados combinados
  const [formData, setFormData] = useState({
    first_name: userData?.first_name || '',
    last_name: userData?.last_name || '',
    email: userData?.email || '',
    date_of_birth: parentInfo?.date_of_birth || '',
    gender: parentInfo?.gender || '',
    nationality: parentInfo?.nationality || '',
  });
  const [errors, setErrors] = useState({});
  const [isModified, setIsModified] = useState(false);

  useEffect(() => {
    const checkIfModified = () => {
      const initialData = {
        first_name: userData?.first_name || '',
        last_name: userData?.last_name || '',
        email: userData?.email || '',
        date_of_birth: parentInfo?.date_of_birth || '',
        gender: parentInfo?.gender || '',
        nationality: parentInfo?.nationality || '',
      };
      return JSON.stringify(formData) !== JSON.stringify(initialData);
    };
    setIsModified(checkIfModified());
  }, [formData, userData, parentInfo]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const formErrors = {};

    // Validación de datos del usuario
    if (!formData.first_name.trim()) {
      formErrors.first_name = 'El nombre es obligatorio.';
    }
    if (!formData.last_name.trim()) {
      formErrors.last_name = 'El apellido es obligatorio.';
    }
    if (formData.first_name.trim().length > 30) {
      formErrors.first_name = 'El nombre debe tener menos de 30 caracteres.';
    }
    if (formData.first_name.trim().length < 2) {
      formErrors.first_name = 'El nombre debe tener más de 2 caracteres.';
    }
    if (formData.last_name.trim().length > 30) {
      formErrors.last_name = 'El apellido debe tener menos de 30 caracteres.';
    }
    if (formData.last_name.trim().length < 2) {
      formErrors.last_name = 'El apellido debe tener más de 2 caracteres.';
    }
    if (formData.email.trim().length > 150) {
      formErrors.email = 'El correo electrónico debe tener menos de 150 caracteres.';
    }

    // Validación de información personal
    const currentDate = new Date();
    const birthDate = new Date(formData.date_of_birth);
    const age = currentDate.getFullYear() - birthDate.getFullYear();
    if (!formData.date_of_birth.trim()) {
      formErrors.date_of_birth = 'La fecha de nacimiento es obligatoria.';
    } else if (age < 18) {
      formErrors.date_of_birth = 'Debe ser mayor de 18 años.';
    } else if (age > 100) {
      formErrors.date_of_birth = 'Debe ser menor de 100 años.';
    }

    if (!formData.gender.trim()) {
      formErrors.gender = 'El género es obligatorio.';
    }

    if (!formData.nationality.trim()) {
      formErrors.nationality = 'La nacionalidad es obligatoria.';
    } else if (formData.nationality.trim().length > 100) {
      formErrors.nationality = 'La nacionalidad debe tener menos de 100 caracteres.';
    } else if (formData.nationality.trim().length < 4) {
      formErrors.nationality = 'La nacionalidad debe tener más de 4 caracteres.';
    }

    setErrors(formErrors);

    if (Object.keys(formErrors).length > 0) {
      return;
    }

    try {
      // Actualizar datos del usuario
      const userUpdateResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/user/${userData.id}/update/`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens?.access}`,
          },
          body: JSON.stringify({
            first_name: formData.first_name,
            last_name: formData.last_name,
          }),
        }
      );

      // Actualizar información personal
      const parentInfoUpdateResponse = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/parent-profile/${contextUserData.profile_id}/info/update/`,
        {
          method: 'PUT',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${authTokens.access}`,
          },
          body: JSON.stringify({
            date_of_birth: formData.date_of_birth,
            gender: formData.gender,
            nationality: formData.nationality,
          }),
        }
      );

      if (userUpdateResponse.ok && parentInfoUpdateResponse.ok) {
        const updatedUserData = await userUpdateResponse.json();
        const updatedParentInfo = await parentInfoUpdateResponse.json();
        onUpdate({ ...updatedUserData, ...updatedParentInfo });
        onClose();
      } else {
        console.error('Error al actualizar los datos del usuario o la información personal');
      }
    } catch (error) {
      console.error('Error al enviar la solicitud:', error.message);
    }
  };

  return (
    <div className="popup-background">
      <div className="popup">
        <div className="popup-content">
          <h3 className="popup-title">Modificar información del perfil</h3>
          <form className="popup-form" onSubmit={handleSubmit}>
            <div className="popup-form-rows">
              <div className="popup-form-double-col">
                <div className={`popup-basic-form-row ${errors.first_name ? 'missing-field' : ''}`}>
                  <label>Nombre:</label>
                  <input type="text" name="first_name" value={formData.first_name} onChange={handleChange} />
                  {errors.first_name && <p className="error-message">{errors.first_name}</p>}
                </div>
                <div className={`popup-basic-form-row ${errors.last_name ? 'missing-field' : ''}`}>
                  <label>Apellido:</label>
                  <input type="text" name="last_name" value={formData.last_name} onChange={handleChange} />
                  {errors.last_name && <p className="error-message">{errors.last_name}</p>}
                </div>
              </div>
              <div className={`popup-basic-form-row ${errors.email ? 'missing-field' : ''}`}>
                <label>Correo Electrónico (No editable):</label>
                <input type="email" name="email" value={formData.email} readOnly disabled />
                {errors.email && <p className="error-message">{errors.email}</p>}
              </div>
              <div className={`popup-basic-form-row ${errors.date_of_birth ? 'missing-field' : ''}`}>
                <label>Fecha de Nacimiento:</label>
                <input type="date" name="date_of_birth" value={formData.date_of_birth} onChange={handleChange} />
                {errors.date_of_birth && <p className="error-message">{errors.date_of_birth}</p>}
              </div>
              <div className={`popup-basic-form-row ${errors.gender ? 'missing-field' : ''}`}>
                <label>Género:</label>
                <select name="gender" value={formData.gender} onChange={handleChange}>
                  <option value="">Seleccionar</option>
                  <option value="male">Masculino</option>
                  <option value="female">Femenino</option>
                </select>
                {errors.gender && <p className="error-message">{errors.gender}</p>}
              </div>
              <div className={`popup-basic-form-row ${errors.nationality ? 'missing-field' : ''}`}>
                <label>Nacionalidad:</label>
                <input type="text" name="nationality" value={formData.nationality} onChange={handleChange} />
                {errors.nationality && <p className="error-message">{errors.nationality}</p>}
              </div>
            </div>
            <div className="buttons-container">
              <button className="primary-btn" type="submit" disabled={!isModified}>
                Guardar cambios
              </button>
              <button className="secondary-btn" type="button" onClick={onClose}>
                Cancelar
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UserProfileUpdate;
